import React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import { resolvePrismicLink } from '../utils';

// Update the path to your Link Resolver

const PreviewPage = () => {
  return (
    <div>
      <h1>loading...</h1>
    </div>
  );
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: 'ipone',
    linkResolver: resolvePrismicLink,
  },
]);